import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../styles/SupportStaff.css";
import susie_waters from "../images/support-staff/susie-waters.jpg";
import brittany_michaelson from "../images/support-staff/brittany-michaelson.jpg";
import landrie_franzen from "../images/support-staff/landrie-franzen.jpg";
import phyllis_collier from "../images/support-staff/phyllis-collier.jpg";
import ashley_prater from "../images/support-staff/Ashley Prater-1.jpg";
import leah_v from "../images/support-staff/leah-v.jpg";
import tammy_w from "../images/support-staff/tammy-w.jpg";
import Footer from "./Footer";

function SupportStaff() {
  const [open, setOpen] = useState(false);
  const [modalBioText, setModalBioText] = useState("");
  const [modalHeaderText, setModalHeaderText] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    document.title = "Stateline MHS - Support Staff"
  }, []);

  return (
    <>
      <div className="ss-title-container">
        <h2>MEET OUR SUPPORT STAFF</h2>
        <h1>
          <i>Click on a name to learn more!</i>
        </h1>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "75%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalHeaderText}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: 14 }}>
            {modalBioText}
          </Typography>
        </Box>
      </Modal>

      <div className="ss-polaroid-container">
          <div className="ss-polaroid">
          <div className="ss-card">
            <img alt="Phyllis Collier" src={phyllis_collier} style={{objectFit: 'cover'}} loading="lazy"></img>
            <div className="ss-card-name">
              <Button
                border="none"
                outline="none"
                style={{width: '100%'}}
                onClick={() => {
                  setModalHeaderText("Phyllis Collier, Client Services");
                  setModalBioText(
                    "Phyllis Collier started at SMHS in September of 2020. Her duties including miscellaneous front desk tasks and being a pleasant face at SMHS. Her favorite color is purple and enjoys spending time with family, listening to music, and loving on her dog."
                  );
                  handleOpen();
                }}
              >
                <p className="ss-polaroid-name">
                  Phyllis Collier, Client Services
                </p>
              </Button>
            </div>
          </div>
        </div>
        <div className="ss-polaroid">
          <div className="ss-card">
            <img alt="Landrie Franzen" src={landrie_franzen} style={{objectFit: 'cover'}} loading="lazy"></img>
            <div className="ss-card-name">
              <Button
                border="none"
                outline="none"
                style={{width: '100%'}}
                onClick={() => {
                  setModalHeaderText("Landrie Franzen, Client Services");
                  setModalBioText(
                    "Landrie started working at SMHS in 2018 at the front desk. She currently manages school based therapy tasks and miscellaneous front desk duties. She enjoys reading, drawing, and painting."
                  );
                  handleOpen();
                }}
              >
                <p className="ss-polaroid-name">
                  Landrie Franzen, Client Services
                </p>
              </Button>
            </div>
          </div>
        </div>
        <div className="ss-polaroid">
          <div className="ss-card">
            <img alt="Brittany Michaelson" src={brittany_michaelson} style={{objectFit: 'cover'}} loading="lazy"></img>
            <div className="ss-card-name">
              <Button
                border="none"
                outline="none"
                style={{width: '100%'}}
                onClick={() => {
                  setModalHeaderText("Brittany Michaelson, Clinic Coordinator");
                  setModalBioText(
                    "Brittany started working for SMHS in July of 2019. She handles a variety of administrative tasks ensuring the clinic's operations run smoothly. She enjoys watching movies, reading psychological thrillers, and spending time with her husband and two kids."
                  );
                  handleOpen();
                }}
              >
                <p className="ss-polaroid-name">
                  Brittany Michaelson, Clinic Coordinator
                </p>
              </Button>
            </div>
          </div>
        </div>
        <div className="ss-polaroid">
          <div className="ss-card">
            <img alt="Ashley Prater" src={ashley_prater} style={{objectFit: 'cover'}} loading="lazy"></img>
            <div className="ss-card-name">
              <Button
                border="none"
                outline="none"
                style={{width: '100%'}}
                onClick={() => {
                  setModalHeaderText("Ashley Prater, Client Services  & Billing Specialist");
                  setModalBioText("Ashley started working for SMHS in October of 2023. Her main duties include insurance verifications, insurance billing , and various front desk tasks. She enjoys camping, watching movies, and spending time with family.");
                  handleOpen();
                }}
              >
                <p className="ss-polaroid-name">
                  Ashley Prater, Client Services
                </p>
              </Button>
            </div>
          </div>
        </div>
        <div className="ss-polaroid">
          <div className="ss-card">
            <img alt="Leah Valderrama" src={leah_v} style={{objectFit: 'cover'}} loading="lazy"></img>
            <div className="ss-card-name">
              <Button
                border="none"
                outline="none"
                style={{width: '100%'}}
              >
                <p className="ss-polaroid-name">
                Leah Valderrama, Client Services
                </p>
              </Button>
            </div>
          </div>
        </div>
        <div className="ss-polaroid">
          <div className="ss-card">
            <img alt="Tammy Waelti" src={tammy_w} style={{objectFit: 'cover'}} loading="lazy"></img>
            <div className="ss-card-name">
              <Button
                border="none"
                outline="none"
                style={{width: '100%'}}
              >
                <p className="ss-polaroid-name">
                Tammy Waelti, Billing Specialist
                </p>
              </Button>
            </div>
          </div>
        </div>
        <div className="ss-polaroid">
          <div className="ss-card">
            <img alt="Susie Waters" src={susie_waters} style={{objectFit: 'cover'}} loading="lazy"></img>
            <div className="ss-card-name">
              <Button
                border="none"
                outline="none"
                style={{width: '100%'}}
                onClick={() => {
                  setModalHeaderText("Susie Waters, Business Manager");
                  setModalBioText(
                    "Susie started at Stateline in August of 2016. Her main duties include managing accounting, business forms and patient statements. She enjoys traveling the US, camping, board games, and playing with her two golden retrievers."
                  );
                  handleOpen();
                }}
              >
                <p className="ss-polaroid-name">
                  Susie Waters, Business Manager
                </p>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="ss-filler-container"></div>

      <Footer />
    </>
  );
}

export default SupportStaff;
