import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { Button, Modal } from "flowbite-react";
import servicesImg from "../images/stateline-booth.jpeg";

const Services = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalBio, setModalBio] = useState("");

  useEffect(() => {
    document.title = "Stateline MHS - Services";
  }, []);

  return (
    <>
      <Modal dismissible show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>{modalHeader}</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">{modalBio}</div>
        </Modal.Body>
      </Modal>

      <section className="relative py-10 xl:pt-14 h-max">
        <div className="mx-auto lg:max-w-full w-full px-5 sm:px-10 md:px-12 lg:px-5 flex flex-col lg:flex-row gap-8 lg:gap-10 xl:gap-12 h-min">
          <div className="mx-auto text-center lg:text-left flex flex-col max-w-3xl justify-center lg:py-8 flex-1 lg:w-1/2 lg:max-w-none justify-center items-center">
            <h1 className="text-indigo-950 dark:text-white text-4xl/snug sm:text-6xl/tight lg:text-5xl/tight xl:text-6xl/tight font-semibold text-center leading-loose">
              SERVICES
            </h1>
            <p className="mt-10 text-gray-700 dark:text-gray-300 lg:text-xl max-w-full lg:max-w-full mx-auto leading-loose lg:px-10 text-center">
              At Stateline Mental Health Services, we offer a wide variety of
              services to our clients ranging from Cognitive-Behavorial Therapy
              to Brainspotting and Eye Movement Desensitization and Reprocessing
              (EMDR).
              <br></br>
              <br></br>
              <br></br>
              <strong>Click on a button below to learn more!</strong>
            </p>
            <br></br>
            <br></br>
            <div className="mt-10 flex gap-12 justify-center flex-wrap">
              <Button
                size="xl"
                className="bg-big-blue rounded-full transition ease-in-out delay-100 hover:scale-125 duration-300"
                onClick={() => {
                  setModalHeader("Areas of Specialty");
                  setModalBio(
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Anxiety / Panic Disorders / Phobias
                      <br></br>
                      Depression / Mood Disorders
                      <br></br>
                      Obsessive Compulsive Disorder (OCD)
                      <br></br>
                      Anger Management
                      <br></br>
                      Marital Concerns
                      <br></br>
                      Stress Management
                      <br></br>
                      Attention Deficit Hyperactivity Disorder (ADHD)
                      <br></br>
                      Autism Spectrum Disorders
                      <br></br>
                      Learning / Developmental Disabilities
                      <br></br>
                      Abuse / Trauma
                      <br></br>
                      Post Traumatic Stress Disorder (PTSD)
                      <br></br>
                      Parent-Child Relationships
                      <br></br>
                      Domestic Violence
                      <br></br>
                      Impulse Disorders
                      <br></br>
                      Eating Disorders
                      <br></br>
                      Grief & Loss Guidance
                      <br></br>
                      Divorce & Blended Family Adjustment
                      <br></br>
                      LGBTQ+ Matters
                      <br></br>
                      Gender Identity
                    </p>
                  );
                  setOpenModal(true);
                }}
              >
                <span className="text-xl py-6">Areas of Specialty</span>
              </Button>
              <Button
                size="xl"
                className="bg-big-blue rounded-full transition ease-in-out delay-100 hover:scale-125 duration-300"
                onClick={() => {
                  setModalHeader("Counseling Services");
                  setModalBio(
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      We provide counseling for individuals ranging the
                      lifespan, couples and families utilizing a variety of
                      techniques and clinical approaches, including, but not
                      limited to:
                      <br></br>
                      <br></br>
                      Cognitive-Behavorial Therapy
                      <br></br>
                      Behavior Therapy
                      <br></br>
                      Parent Training
                      <br></br>
                      Trauma-Informed Therapy
                      <br></br>
                      Mindfulness
                      <br></br>
                      Client-Centered Approach
                      <br></br>
                      Play Therapy
                      <br></br>
                      Family Systems Approach
                      <br></br>
                      Eye Movement Desensitization and Reprocessing (EMDR)
                      <br></br>
                      Brainspotting
                      <br></br>
                      Dialectical Behavioral Therapy (DBT)
                      <br></br>
                      Court-Ordered Specialized Family Therapy*{" "}
                      <span className="text-sm">
                        <i>including reunification</i>
                      </span>
                    </p>
                  );
                  setOpenModal(true);
                }}
              >
                <span className="text-xl py-6">Counseling Services</span>
              </Button>
              <Button
                size="xl"
                className="bg-big-blue rounded-full transition ease-in-out delay-100 hover:scale-125 duration-300"
                onClick={() => {
                  setModalHeader("Medication Management");
                  setModalBio(
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Comprehensive psychiatric diagnostic evaluations
                      <br></br>
                      Medication evaluation and management
                      <br></br>
                      Order, perform, and interpret lab tests and other
                      diagnostic studies
                      <br></br>
                      Provide integrative and complementary medicine
                      interventions
                      <br></br>
                      GenSight testing to evaluate how your genes may affect
                      medication outcomes
                    </p>
                  );
                  setOpenModal(true);
                }}
              >
                <span className="text-xl py-6">Medication Management</span>
              </Button>
              <Button
                size="xl"
                className="bg-big-blue rounded-full transition ease-in-out delay-100 hover:scale-125 duration-300"
                onClick={() => {
                  setModalHeader("Payment Options");
                  setModalBio(
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                      Stateline Mental Health Services accepts a variety of
                      payment options, including Dean, Mercy, BadgerCare, many
                      HMO's and offers a cash pay rate.
                      <br></br>
                      <br></br>
                      Please call our office at{" "}
                      <a href="tel:6083688087">
                        <strong>
                          <u>(608) 368-8087</u>
                        </strong>
                      </a>{" "}
                      if you have questions about whether or not we are
                      contracted with your insurance provider. We are happy to
                      help you navigate the process of receiving services
                      through your insurance carrier.
                      <br></br>
                      <br></br>
                      For those not covered by insurance or insurance providers
                      not paired with us, we have self-pay options at a reduced
                      rate available.
                    </p>
                  );
                  setOpenModal(true);
                }}
              >
                <span className="text-xl py-6">Payment Options</span>
              </Button>
            </div>
          </div>
          <div className="flex flex-1 lg:w-1/2 relative max-w-3xl h-auto mx-auto lg:max-w-none">
            <img
              src={servicesImg}
              alt=""
              height="auto"
              className="object-fit rounded-xl"
            />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Services;
